@keyframes slideUp {
  from {
    transform: translateY(98px);
  }

  to {
    transform: translateY(0);
  }
}

.slideUp {
  animation-name: slideUp;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(98px);
  }
}

.slideDown {
  animation-name: slideDown;
}
