body {
  overflow-y: scroll;
  margin: 0;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  line-height: 1;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

input {
  border-radius: 0;
  text-indent: 0;
  padding: 0;
}

input:disabled {
  opacity: 1;
}

a,
button,
select,
input {
  background: transparent;
  outline: none;
  font-size: 0.875rem;
}

p,
li,
td {
  font-weight: 400;
}
